const testimonialData = [
  {
    imgSrc: "https://avatars.githubusercontent.com/u/31277330?v=4",
    description:
      "Waheguru Ji Ka Khalsa Waheguru Ji Ki Fateh,Sir, i m very much impressed with ur lectures which are actually the way of life under the shadows of guru's teachings.",
    name: "Harjinder Singh",
    designation: "INDIA",
  },
  {
    imgSrc: "https://avatars.githubusercontent.com/u/63964451?v=4",
    description: `Gurfateh ji, LT has entirely changed my way of looking at people around me. Earlier i used to judge people, like i should not talk to that person because he is mean or selfish, etc, etc. But now i don't judge anyone, veerji once said "we don't have any right to judge anyone, lets judge ourselves first.`,
    name: "Arshdeep Singh",
    designation: "Maruitius",
  },
  {
    imgSrc: "https://avatars.githubusercontent.com/u/36703557?v=4",
    description:
      "sat sri akal veer g,its really great to listen and finally understand y we r here as human beings and how our guru sahiban has taught us the most easy and practical way of living.still there r few queries i want to sort out,please help me with that. ",
    name: "Parvez Kaur Hora",
    designation: "India",
  },
];

export default testimonialData;
